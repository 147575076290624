export function listMenu(module?: string) {
  const nuxt = useNuxtApp()

  const currentModule = module || nuxt.$getActiveModule()

  let menus: any[] = []; let prefix = ''

  if (currentModule === 'stores') {
    prefix = '/stores/{storeId}/'
    menus = [
      {
        title: nuxt.$getTranslation('navigation.storeProfile'),
        icon: 'store',
        link: `${prefix}profile`,
        module: 'profile',
      },
      {
        title: nuxt.$getTranslation('navigation.transactional'),
        link: null,
        children: [
          {
            title: nuxt.$getTranslation('modules.sales.title'),
            link: null,
            children: [
              {
                title: nuxt.$getTranslation('modules.salesList.title'),
                icon: 'storefront',
                link: `${prefix}sales`,
                module: 'sales',
              },
              {
                title: nuxt.$getTranslation('modules.stockCheckings.title'),
                icon: 'fact_check',
                link: `${prefix}stock-checkings`,
                module: 'stock checkings',
              },
              {
                title: nuxt.$getTranslation('modules.priceCheck.title'),
                icon: 'money',
                link: `${prefix}price-checks`,
                module: 'price checks',
              },
              {
                title: nuxt.$getTranslation('modules.saleReturns.title'),
                icon: 'east',
                link: `${prefix}sale-returns`,
                module: 'sale returns',
              },
            ],
          },
          {
            title: nuxt.$getTranslation('modules.purchases.title'),
            link: null,
            children: [
              {
                title: nuxt.$getTranslation('modules.purchaseReceivings.title'),
                icon: 'local_shipping',
                link: `${prefix}purchase-receivings`,
                module: 'purchases',
              },
              {
                title: nuxt.$getTranslation('modules.purchases.title'),
                icon: 'shop',
                link: `${prefix}purchases`,
                module: 'purchases',
              },
              {
                title: nuxt.$getTranslation('modules.purchaseReturns.title'),
                icon: 'west',
                link: `${prefix}purchase-returns`,
                module: 'purchase returns',
              },
              {
                title: nuxt.$getTranslation('modules.expeditionTransactions.title'),
                icon: 'local_shipping',
                link: `${prefix}expedition-transactions`,
                module: 'expedition-transactions',
              },
            ],
          },
          {
            title: nuxt.$getTranslation('modules.cashBanking.title'),
            link: null,
            children: [
              {
                title: nuxt.$getTranslation('modules.deposits.title'),
                icon: 'add_shopping_cart',
                link: `${prefix}deposits`,
                module: 'deposits',
              },
              {
                title: nuxt.$getTranslation('modules.expenses.title'),
                icon: 'local_atm',
                link: `${prefix}expenses`,
                module: 'expenses',
              },
            ],
          },
          {
            title: nuxt.$getTranslation('modules.ledger.title'),
            link: null,
            children: [
              {
                title: nuxt.$getTranslation('modules.generalJournals.title'),
                icon: 'library_books',
                link: prefix + 'general-journals',
                module: 'general journals'
              },
            ]
          },
          {
            title: nuxt.$getTranslation('modules.assets.title'),
            link: null,
            children: [
              {
                title: nuxt.$getTranslation('modules.assets.title'),
                icon: 'real_estate_agent',
                link: prefix + 'assets',
                module: 'assets'
              },
            ]
          },
        ]
      },
      {
        title: nuxt.$getTranslation('navigation.report'),
        link: null,
        children: [
          {
            title: nuxt.$getTranslation('modules.saleReports.title'),
            icon: 'assessment',
            link: `${prefix}reports/sales`,
            module: 'sale reports',
          },
          {
            title: nuxt.$getTranslation('modules.purchaseReports.title'),
            icon: 'bar_chart',
            link: `${prefix}reports/purchases`,
            module: 'purchase reports',
          },
          {
            title: nuxt.$getTranslation('modules.stockAlerts.title'),
            icon: 'inventory_2',
            link: `${prefix}reports/stock-alerts`,
            module: 'stock alerts',
          },
        ],
      },
      {
        title: nuxt.$getTranslation('navigation.masterData'),
        link: null,
        children: [
          {
            title: nuxt.$getTranslation('modules.items.title'),
            link: null,
            children: [
              {
                title: nuxt.$getTranslation('modules.items.title'),
                icon: 'inventory',
                link: `${prefix}items`,
                module: 'items',
              },
              {
                title: nuxt.$getTranslation('modules.printBarcodes.title'),
                icon: 'print',
                link: `${prefix}print-barcodes`,
                module: 'print barcodes',
              },
              {
                title: nuxt.$getTranslation('modules.categories.title'),
                icon: 'category',
                link: `${prefix}categories`,
                module: 'categories',
              },
              {
                title: nuxt.$getTranslation('modules.units.title'),
                icon: 'scale',
                link: `${prefix}units`,
                module: 'units',
              },
              {
                title: nuxt.$getTranslation('modules.priceCategories.title'),
                icon: 'payments',
                link: `${prefix}price-categories`,
                module: 'price categories',
              },
            ],
          },
          {
            title: nuxt.$getTranslation('shared.columns.point'),
            link: null,
            children: [
              {
                title: nuxt.$getTranslation('shared.labels.setting'),
                icon: 'settings',
                link: `${prefix}item-points`,
                module: 'setting point',
              },
              {
                title: nuxt.$getTranslation('shared.buttons.history'),
                icon: 'history',
                link: `${prefix}points/histories`,
                module: 'history point',
              },
            ],
          },
          {
            title: nuxt.$getTranslation('modules.assets.title'),
            link: null,
            children: [
              {
                title: nuxt.$getTranslation('modules.assetTypes.title'),
                icon: 'real_estate_agent',
                link: `${prefix}asset-types`,
                module: 'asset types',
              },
              {
                title: nuxt.$getTranslation('modules.assetDepreciations.title'),
                icon: 'real_estate_agent',
                link: `${prefix}asset-depreciations`,
                module: 'asset depreciations',
              },
            ],
          },
          {
            title: nuxt.$getTranslation('modules.suppliers.title'),
            icon: 'business',
            link: `${prefix}suppliers`,
            module: 'suppliers',
          },
          {
            title: nuxt.$getTranslation('modules.salespersons.title'),
            icon: 'support_agent',
            link: `${prefix}salespersons`,
            module: 'salespersons',
          },
          {
            title: nuxt.$getTranslation('modules.customers.title'),
            icon: 'person',
            link: `${prefix}customers`,
            module: 'customers',
          },
          {
            title: nuxt.$getTranslation('modules.serviceTypes.title'),
            icon: 'category',
            link: `${prefix}service-types`,
            module: 'service types',
          },
          {
            title: nuxt.$getTranslation('modules.accountTypes.title'),
            icon: 'account_balance_wallet',
            link: `${prefix}account-types`,
            module: 'account types',
          },
          {
            title: nuxt.$getTranslation('modules.expeditions.title'),
            icon: 'local_shipping',
            link: `${prefix}expeditions`,
            module: 'expeditions',
          },
          {
            title: nuxt.$getTranslation('modules.printerSettings.title'),
            icon: 'print',
            link: `${prefix}printer-settings`,
            module: 'printer settings',
          },
        ],
      },
    ]
  }
  else if (currentModule === 'warehouses') {
    prefix = '/warehouses/{warehouseId}/'
    menus = [
      {
        title: nuxt.$getTranslation('navigation.transactional'),
        link: null,
        children: [
          {
            title: nuxt.$getTranslation('modules.itemDeliveries.title'),
            icon: 'local_shipping',
            link: `${prefix}item-deliveries`,
            module: 'item deliveries',
          },
          {
            title: nuxt.$getTranslation('modules.stockTransfers.title'),
            icon: 'swap_horiz',
            link: `${prefix}stock-transfers`,
            module: 'stock transfers',
          },
          {
            title: nuxt.$getTranslation('modules.stockAdjustments.title'),
            icon: 'border_color',
            link: `${prefix}stock-adjustments`,
            module: 'stock adjustments',
          },
          // {
          //   title: nuxt.$getTranslation('modules.warehouseReceivings.title'),
          //   icon: 'local_shipping',
          //   link: prefix + 'warehouse-receivings',
          //   module: 'warehouse receivings'
          // },
        ]
      }
    ];
  } else if (currentModule === 'companies') {
    prefix = '/companies/'
    menus = [
      {
        title: nuxt.$getTranslation('navigation.operational'),
        link: null,
        children: [
          {
            title: nuxt.$getTranslation('modules.companies.title'),
            icon: 'domain',
            link: `${prefix}companies`,
            module: 'companies',
          },
          {
            title: nuxt.$getTranslation('modules.stores.title'),
            icon: 'store',
            link: `${prefix}stores`,
            module: 'stores',
          },
          {
            title: nuxt.$getTranslation('modules.warehouses.title'),
            icon: 'warehouse',
            link: `${prefix}warehouses`,
            module: 'warehouses',
          },
          {
            title: nuxt.$getTranslation('modules.saleGroups.title'),
            icon: 'storefront',
            link: `${prefix}sales`,
            module: 'sale multistores',
          },
        ]
      },
      {
        title: nuxt.$getTranslation('modules.users.title'),
        link: null,
        children: [
          {
            title: nuxt.$getTranslation('modules.users.title'),
            icon: 'person',
            link: `${prefix}users`,
            module: 'users',
          },
          {
            title: nuxt.$getTranslation('modules.roles.title'),
            icon: 'lock_person',
            link: `${prefix}roles`,
            module: 'roles',
          },
          {
            title: nuxt.$getTranslation('modules.permissions.title'),
            icon: 'check',
            link: `${prefix}permission`,
            module: 'permission',
          }
        ],
      },
      {
        title: nuxt.$getTranslation('modules.employees.title'),
        link: null,
        children: [
          {
            title: nuxt.$getTranslation('modules.shifts.title'),
            icon: 'pending_actions',
            link: `${prefix}shifts`,
            module: 'shifts',
          },
          {
            title: nuxt.$getTranslation('modules.employees.title'),
            icon: 'people',
            link: `${prefix}employees`,
            module: 'employees',
          },
          {
            title: nuxt.$getTranslation('modules.salaries.title'),
            icon: 'payments',
            link: `${prefix}salaries`,
            module: 'salaries',
          },
          {
            title: nuxt.$getTranslation('modules.attendances.title'),
            icon: 'assignment_ind',
            link: `${prefix}attendances`,
            module: 'attendances',
          },
        ]
      }
    ]
  }

  menus = [
    {
      title: nuxt.$getTranslation('navigation.dashboard'),
      icon: 'dashboard',
      link: '/',
      module: '/',
    },
    ...menus,
  ]

  return menus
}
