{
  "navigation": {
    "menu": "Menu",
    "masterData": "Master Data",
    "transactional": "Transaction",
    "report": "Reports",
    "home": "Home",
    "showcase": "Show Case",
    "dashboard": "Dashboard",
    "storeProfile": "Store Profile",
    "storeAudit": "Store Audit",
    "stores": "Store",
    "shift": "Shift",
    "warehouses": "Warehouse",
    "companies": "Company",
    "operational": "Operational",
    "allStoreSales": "All Store Sales"
  },
  "shared": {
    "columns": {
      "hasAllAccess": "Has All Access",
      "id": "ID",
      "balance": "Balance",
      "action": "Action",
      "name": "Name",
      "shortName": "Short Name",
      "email": "Email",
      "username": "Username",
      "phone": "Phone",
      "barcode": "Barcode",
      "code": "Code",
      "category": "Category",
      "subCategory": "Sub Category",
      "description": "Description",
      "unit": "Unit",
      "sellPrice": "Sell Price",
      "salesperson": "Salesperson",
      "commissionPercentage": "Commission Percentage",
      "commission": "Commission",
      "purchasePrice": "Purchase Price",
      "defaultPrice": "Default Price",
      "cost": "Cost",
      "fee": "Fee",
      "makeIt": "Make It",
      "profit": "Profit",
      "priceCategory": "Price Category",
      "invoice": "Invoice",
      "deliveryOrder": "Delivery Order",
      "deliveryOrderNumber": "Delivery Order Number",
      "invoiceNumber": "Invoice Number",
      "voucherNumber": "Voucher Number",
      "invoicePrefix": "Invoice Prefix",
      "taxInvoiceNumber": "Tax Invoice Number",
      "identityNumber": "Identity Number",
      "npwp": "NPWP",
      "point": "Point",
      "pointToRupiah": "Point to Rupiah",
      "rupiahToPoint": "Rupiah to Point",
      "date": "Date",
      "startDate": "Start Date",
      "endDate": "End Date",
      "invoiceDate": "Invoice Date",
      "dueDate": "Due Date",
      "reportDate": "Report Date",
      "receiveDate": "Received Date",
      "quantity": "Quantity",
      "checkedQuantity": "Checked Qty",
      "receivedQuantity": "Received Qty",
      "returnQuantity": "Return Qty",
      "returnQuantityOld": "Return Qty Old",
      "returnQuantityNew": "Return Qty New",
      "deliveryOrderQuantity": "Delivery Order Qty",
      "outstandingQuantity": "Outstanding Qty",
      "currentQuantity": "Current Quantity",
      "currentPoint": "Current Point",
      "stockName": "Stock Name",
      "newStock": "New Stock",
      "stockBefore": "Stock Before",
      "stockAfter": "Stock After",
      "store": "Store",
      "shift": "Shift",
      "stock": "Stock",
      "stockAlert": "Stock Alert",
      "currentStock": "Current Stock",
      "supplier": "Supplier",
      "customer": "Customer",
      "item": "Item",
      "totalItem": "Total Item",
      "basePrice": "Base Price",
      "itemGroup": "Item Group",
      "amount": "Amount",
      "invoiceAmount": "Invoice Amount",
      "serviceAmount": "Service Amount",
      "warehouse": "Warehouse",
      "warehouseFrom": "Warehouse From",
      "warehouseTo": "Warehouse To",
      "quantityFrom": "Quantity From",
      "quantityTo": "Quantity To",
      "password": "Password",
      "repeatPassword": "Repeat Password",
      "paidAmount": "Paid Amount",
      "returnedAmount": "Returned Amount",
      "outstandingAmount": "Unpaid Amount",
      "required": "Required",
      "price": "Price",
      "finalPrice": "Final Price",
      "status": "Status",
      "account": "Account",
      "parentAccount": "Parent Account",
      "paymentAccount": "Payment Account",
      "paymentGatewayMethod": "Payment Gateway Method",
      "paymentNumber": "Payment Number",
      "paymentNumberLink": "Payment Link",
      "paymentReferenceId": "Reference ID",
      "paymentExpiredDate": "Expired Date",
      "paymentStatus": "Payment Status",
      "paymentAmount": "Payment Amount",
      "accountNumber": "Account Number",
      "accountName": "Account Name",
      "accountType": "Account Type",
      "createdAt": "Created At",
      "createdBy": "Created By",
      "editedAt": "Edited At",
      "editedBy": "Edited By",
      "deletedAt": "Deleted At",
      "deletedBy": "Deleted By",
      "paidAt": "Paid At",
      "expedition": "Expedition",
      "shippingPrice": "Shipping Price",
      "pointAmount": "Point Amount",
      "discount": "Discount",
      "discountPrice": "Discount Price Rp.",
      "discountAmount": "Discount Amount",
      "discountPercentage": "Discount Percentage",
      "discountValue": "Discount Value",
      "discountValueReturn": "Discount Value Return",
      "discountedSellPrice": "Discounted Sell Price",
      "deliveryStatus": "Delivery Status",
      "stockStatus": "Stock Status",
      "deliveredAt": "Delivered At",
      "limitOutstandingAmount": "Limit Outstanding Amount",
      "whatsapp": "Whatsapp",
      "otp": "OTP",
      "tax": "Tax",
      "taxReturn": "Tax Return",
      "taxExpedition": "Tax Expedition",
      "saleLowerPricePermission": "Lower Price Permission",
      "whatsappNumber": "Lower Price Whatsapp Number",
      "isWhatsappGroup": "Is Whatsapp Group",
      "isActive": "Is Active",
      "role": "Role",
      "permission": "Permission",
      "year": "Year",
      "canceled": "Canceled",
      "ordered": "Ordered",
      "pic": "PIC",
      "entry": "Entry",
      "address": "Address",
      "policeNumber": "Police Number",
      "source": "Source",
      "destination": "Destination",
      "isSellable": "Is Sellable",
      "isBaseUnit": "Is Base Unit",
      "fileName": "File Name",
      "printingPreference": "Printing Preference",
      "sale": "Sale",
      "numberOfPage": "Number of Page",
      "numberOfPrintedPages": "Number of Printed Pages",
      "numberOfPrintedCopies": "Number of Printed Copies",
      "printedAt": "Printed At",
      "barcodeImage": "Barcode Image",
      "select": "Select",
      "numberOfCopies": "Number of Copies",
      "pin": "PIN",
      "sn": "Serial Number",
      "monthlysalary": "Monthly Salary",
      "bonussalary": "Bonus Salary",
      "overtimehourlysalary": "Overtime Hourly Salary",
      "latesalarydeduction": "Late Salary Deduction",
      "lateminuteinterval": "Late Minute Interval",
      "minute": "Minute",
      "hour": "Hour",
      "dailysalary": "Daily Salary",
      "dayOfWeek": "Day of Week",
      "month": "Month",
      "debit": "Debit",
      "credit": "Credit",
      "memo": "Memo",
      "module": "Module",
      "time": "Time",
      "startTime": "Start Time",
      "endTime": "End Keluar",
      "endstartTime": "End Time",
      "totalWorkHour": "Total Work Hour",
      "overtimeAdjustmentHour": "Overtime Adjustment Hour",
      "checkIn": "Check In",
      "checkOut": "Check Out",
      "workHour": "Work Hour",
      "accumulation": "Accumulation",
      "absent": "Absent",
      "present": "Present",
      "late": "Late",
      "overtime": "Overtime",
      "isOnVacation": "Is On Vacation",
      "isOvertimeApproved": "Is Overtime Approved",
      "paidDailySalary": "Paid Daily Salary",
      "company": "Company",
      "isIntangible": "Intangible",
      "yearlyDepreciationPercentage": "Yearly Depreciation Percentage",
      "assetType": "Asset Type",
      "assetDepreciation": "Asset Depreciation",
      "dateOfUse": "Date of Use",
      "purchaseDate": "Purchase Date",
      "ageInMonth": "Age in Month",
      "accumulatedDepreciationAccount": "Accumulated Depreciation Account",
      "depreciationExpenseAccount": "Depreciation Expense Account",
      "totalData": "Total Data",
      "processedData": "Processed Data",
      "error": "Error"
    },
    "buttons": {
      "back": "Back",
      "create": "Create",
      "payment": "Payment",
      "delivery": "Delivery",
      "submit": "Submit",
      "reset": "Reset",
      "cancel": "Cancel",
      "show": "Show",
      "delete": "Delete",
      "add": "Add",
      "edit": "Edit",
      "detail": "Detail",
      "confirm": "Confirm",
      "close": "Close",
      "yes": "Yes",
      "no": "No",
      "login": "Login",
      "receive": "Receive",
      "send": "Send",
      "transfer": "Transfer",
      "adjustments": "Adjustment",
      "return": "Return",
      "history": "History",
      "notify": "Send Notification",
      "getPrinters": "Get Printers",
      "copy": "Copy",
      "print": "Print",
      "printAll": "Print All",
      "testPrint": "Test Print",
      "purchaseInvoice": "Purchase Invoice",
      "download": "Download",
      "updateSales": "Update Sales",
      "updateCustomer": "Update Customer",
      "outOfStock": "Out Of Stock",
      "paymentGateway": "Payment Gateway",
      "openInNewTab": "Open In New Tab"
    },
    "inputs": {
      "search": "Search"
    },
    "labels": {
      "columns": "Columns",
      "confirmSubmit": "Are you sure you want to save this data??",
      "confirmDelete": "Are you sure you want to delete this data??",
      "confirmCancel": "Are you sure you want to cancel this data??",
      "noData": "Tidak ada data",
      "noSearchResut": "Pencarian data tidak ditemukan",
      "required": "Data wajib diisi",
      "date": "Tanggal tidak sesuai",
      "positiveNumber": "Angka tidak sesuai",
      "nonNegativeNumber": "Angka tidak dapat negatif",
      "noCommaNumber": "Please enter a valid input (No Comma Number)",
      "notFound": "Tidak ada data ditemukan",
      "success": "Berhasil",
      "failed": "Gagal",
      "subTotalPrice": "Sub Total Price",
      "totalPrice": "Total Harga",
      "totalReturnAmount": "Total Return Amount",
      "getPoint": "Get Point",
      "exchangePoint": "Exchange Point",
      "exchangeItem": "Exchange Item",
      "afterTax": "Setelah Pajak",
      "tax": "Pajak",
      "settled": "Lunas",
      "paid": "Dibayar",
      "outstanding": "Hutang",
      "downPayment": "DP",
      "addPayment": "Tambah Pembayaran",
      "addExpedition": "Tambah Ekspedisi",
      "paymentMethod": "Pembayaran",
      "list": "List",
      "maxValue": "Maximum ",
      "minValue": "Minimum ",
      "maxDiscount": "Maximum 99%",
      "qtyReceivingMaxValue": "Qty Diterima melebihi qty barang",
      "qtyEmpty": "Qty masih kosong",
      "exceedStock": "Melebihi permintaan stok barang",
      "totalSales": "Total Sales",
      "totalDefaultPrice": "Total Default Price",
      "totalFinalSellPrice": "Total Final Sell Price",
      "barcodeDefaultSameAsCode": "Empty barcode will be set as code",
      "completeForm": "Complete Form",
      "includeTax": "Include Tax",
      "excludeTax": "Exclude Tax",
      "oneItemPriceCheaper": "One of the item’s price is cheaper than min. price",
      "request": "Request",
      "verify": "Verify",
      "resend": "Resend",
      "timeRemaining": "Time Remaining",
      "sendAll": "Send All",
      "default": "Default",
      "fileName": "File Name",
      "ipAddress": "IP Address",
      "printerName": "Printer Name",
      "paperSize": "Paper Size",
      "orientation": "Orientation",
      "color": "Color",
      "copies": "Copies",
      "collate": "Collate",
      "deleteFile": "Delete File",
      "wrongInput": "Data masukan salah",
      "onPrinting": "On Printing",
      "printed": "Printed",
      "notPrintedYet": "Not Printed Yet",
      "inputDataCustomer": "Input Data Customer",
      "day": "Day",
      "day1": "Sunday",
      "day2": "Monday",
      "day3": "Tuesday",
      "day4": "Wednesday",
      "day5": "Thursday",
      "day6": "Friday",
      "day7": "Saturday",
      "done": "Done",
      "pending": "Pending",
      "canceled": "Canceled",
      "ordered": "Ordered",
      "delivered": "Delivered",
      "expired": "Expired",
      "inactive": "Inactive",
      "active": "Active",
      "detail": "Detail",
      "expiredDay": "Expired Day",
      "notifications" : {
        "sales" : "Sales",
        "purchase" : "Purchase",
        "stock" : "Stock",
        "outstandingPurchase" : "Outstanding Purchases"
      },
      "all": "All",
      "allocate": "Allocate",
      "commission": "Commissions",
      "byWarehouse": "By Warehouse",
      "setting": "Setting",
      "pointUsed": "Point Used",
      "pointNotEnough": "Point Not Enough",
      "closeStore": "Close Store",
      "processAudit": "Process Audit",
      "startTime": "Start Time",
      "endTime": "End Time",
      "greaterThanInvoiceDate": "Must be greater than the invoice date",
      "uploadImagesHere" : "Upload Images Here",
      "clear": "Clear",
      "clearAll": "Clear All",
      "pickFiles": "Pick Files",
      "galeryPhoto": "Galery Photo",
      "reason": "Reason",
      "fullCash": "Full Cash",
      "readyStock": "Ready Stock"
    },
    "notifications": {
      "loginSuccess": "Login success",
      "logoutSuccess": "Logout success",
      "deleted": "Data deleted",
      "storeChanged": "Store changed",
      "warehouseChanged": "Warehouse changed",
      "finalSellPriceUpdated": "Final sell price updated",
      "unauthorized": "Access unauthorized",
      "repeatPasswordShouldBeSame": "Repeat password should be same as password",
      "itemUnitThereMustBeActiveUnit": "item unit there must be active unit",
      "getPrinterSuccess": "Get printer data success",
      "getPrinterFailed": "Get printer data failed",
      "setPrinterSuccess": "Printer setting success",
      "setPrinterFailed": "Printer setting failed",
      "printerNotSetup": "Printer has not been setup",
      "connectionTimeout": "Connection timeout",
      "addressUnreachable": "Address unreachable",
      "printingSuccess": "Printing success",
      "printingFailed": "Printing failed",
      "dataHasBeenAdded": "Data has been added",
      "addDeliveryOrderAlertDialog": "Customer data has not been filled in.",
      "cannotDeleteItem": "Cannot delete item",
      "copied": "Copied",
      "failedToCopy": "Failed to copy"
    },
    "permissions": {
      "view": "View",
      "create": "Create",
      "update": "Update",
      "delete": "Delete",
      "cancel": "Cancel",
      "return": "Return",
      "viewHistorySaleItem": "View history sale item",
      "viewHistoryPurchaseItem": "View history purchase item",
      "addPayment": "Add payment",
      "printInvoice": "Print invoice",
      "printDeliveryOrder": "Print delivery order",
      "receiveItem": "Receive item",
      "filterDate": "Filter date",
      "filterYear": "Filter year",
      "sendNotification": "Send notification",
      "process": "Process"
    }
  },
  "modules": {
    "assets": {
      "title": "Assets"
    },
    "assetDepreciations": {
      "title": "Asset Depreciations"
    },
    "assetTypes": {
      "title": "Asset Types"
    },
    "items": {
      "title": "Items",
      "basePrice": "Item Base Prices"
    },
    "itemPoints": {
      "title": "Item Points"
    },
    "itemStocks": {
      "title": "Item Stocks"
    },
    "itemStockAdjustments": {
      "title": "Item Stock Adjustments"
    },
    "itemUnits": {
      "title": "Item Units"
    },
    "itemDeliveries": {
      "title": "Item Deliveries"
    },
    "categories": {
      "title": "Categories"
    },
    "subCategories": {
      "title": "Sub Categories"
    },
    "warehouses": {
      "title": "Warehouses"
    },
    "suppliers": {
      "title": "Suppliers"
    },
    "purchases": {
      "title": "Purchases"
    },
    "purchaseItems": {
      "title": "Purchase Items"
    },
    "purchaseReceivings": {
      "title": "Purchase Receivings"
    },
    "purchasesList": {
      "title": "Purchase List"
    },
    "customers": {
      "title": "Customers"
    },
    "sales": {
      "title": "Sales"
    },
    "saleItems": {
      "title": "Sale Items"
    },
    "salesList": {
      "title": "Sales List"
    },
    "saleGroups": {
      "title": "Sale Groups"
    },
    "priceCheck": {
      "title": "Price Check"
    },
    "stockCheckings": {
      "title": "Stock Checking"
    },
    "purchaseReturns": {
      "title": "Purchase Returns"
    },
    "purchaseReturnItems": {
      "title": "Purchase Return Items"
    },
    "saleReturns": {
      "title": "Sale Returns"
    },
    "saleReturnItems": {
      "title": "Sale Return Items"
    },
    "stores": {
      "title": "Stores"
    },
    "shifts": {
      "title": "Shifts"
    },
    "units": {
      "title": "Units"
    },
    "priceCategories": {
      "title": "Price Categories"
    },
    "accountTypes": {
      "title": "Account Types",
      "name": "Account Types Name"
    },
    "accounts": {
      "title": "Accounts",
      "name": "Accounts Name",
      "nameParents": "Parent Accounts Name"
    },
    "defaultAccounts": {
      "title": "Default Accounts"
    },
    "expeditions": {
      "title": "Expeditions"
    },
    "serviceTypes": {
      "title": "Service Types"
    },
    "warehouseReceivings": {
      "title": "Warehouse Receivings"
    },
    "adminReceivings": {
      "title": "Admin Receivings"
    },
    "stockTransfers": {
      "title": "Stock Transfers"
    },
    "stockAdjustments": {
      "title": "Stock Adjustments"
    },
    "users": {
      "title": "Users"
    },
    "userRoles": {
      "title": "User Roles"
    },
    "roles": {
      "title": "Roles"
    },
    "rolePermissions": {
      "title": "Role Permissions"
    },
    "salespersons": {
      "title": "SalesPersons"
    },
    "salespersonCommissions": {
      "title": "SalesPerson Commissions"
    },
    "saleReports": {
      "title": "Sales Reports"
    },
    "stockAlerts": {
      "title": "Stock Alerts"
    },
    "outstandingSales": {
      "title": "Outstanding Sales"
    },
    "purchaseReports": {
      "title": "Purchase Reports"
    },
    "outstandingPurchases": {
      "title": "Outstanding Purchases"
    },
    "cashBanking": {
      "title": "Cash and Banking"
    },
    "generalJournals": {
      "title": "General Journals"
    },
    "generalJournalEntries": {
      "title": "General Journal Entries"
    },
    "ledger": {
      "title": "Ledger"
    },
    "deposits": {
      "title": "Deposits"
    },
    "expenses": {
      "title": "Expenses"
    },
    "printerSettings": {
      "title": "Printer Settings"
    },
    "printBarcodes": {
      "title": "Print Barcodes"
    },
    "employees": {
      "title": "Employee"
    },
    "shiftSchedules": {
      "title": "Shift Schedule"
    },
    "salaries": {
      "title": "Salaries"
    },
    "deliveryOrders": {
      "title": "Delivery Orders"
    },
    "attendances": {
      "title": "Attendances"
    },
    "permissions": {
      "title": "Permission Access"
    },
    "expeditionTransactions": {
      "title": "Expedition Transactions"
    },
    "companies" : {
      "title" : "Company"
    },
    "salesTax": {
      "title": "Sales Tax"
    },
    "salesMerchandiseInventory": {
      "title": "Sales Merchandise Inventory"
    },
    "salesAccountReceivable": {
      "title": "Sales Account Receivable"
    },
    "salesCostOfGoodSold": {
      "title": "Sales Cost of Good Sold"
    },
    "purchasesTax": {
      "title": "Purchases Tax"
    },
    "purchasesDebt": {
      "title": "Purchases Debt"
    },
    "purchasesDebtTax": {
      "title": "Purchases Debt Tax"
    },
    "expeditionsTax": {
      "title": "Expeditions Tax"
    },
    "expeditionsDebt": {
      "title": "Expeditions Debt"
    },
    "expeditionsDebtTax": {
      "title": "Expeditions Debt Tax"
    },
    "saleJurnal": {
      "title": "Sales"
    },
    "saleTransaction": {
      "title": "Sales"
    },
    "saleReturnJurnalIn": {
      "title": "Sale Returns In"
    },
    "saleReturnJurnalOut": {
      "title": "Sale Returns Out"
    },
    "saleReturnTransactionIn": {
      "title": "Sale Returns In"
    },
    "saleReturnTransactionOut": {
      "title": "Sale Returns Out"
    },
    "purchaseJurnal": {
      "title": "Purchases"
    },
    "purchaseTransaction": {
      "title": "Purchases"
    },
    "purchaseReturnJurnalIn": {
      "title": "Purchase Returns In"
    },
    "purchaseReturnJurnalOut": {
      "title": "Purchase Returns Out"
    },
    "purchaseReturnTransactionIn": {
      "title": "Purchase Returns In"
    },
    "purchaseReturnTransactionOut": {
      "title": "Purchase Returns Out"
    },
    "expeditionJurnal": {
      "title": "Expeditions"
    },
    "expeditionTransaction": {
      "title": "Expeditions"
    },
    "salaryJurnal": {
      "title": "Salaries"
    },
    "salaryTransaction": {
      "title": "Salaries"
    },
    "audit": {
      "title": "Audits"
    }
  }
}